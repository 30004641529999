/* ChatViewComponent.css */

.chat-container {
  margin-top: 20px;
  overflow-x: hidden;
}

.chat-message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  position: relative;
}

.current-user {
  justify-content: flex-end;
}

.other-user {
  justify-content: flex-start;
  align-items: flex-end !important;
}

.message-content {
  position: relative;
}

.message-paper {
  display: flex;
  max-width: 70%;
  position: relative;
}

.current-user-paper-custumer {
  background-color: #575ff6 !important;
  border-radius: 30px 30px 6px 30px !important;
  color: #ffffff !important;
  font-size: 14px !important;
  padding: 10px 25px 10px 10px !important;
}

.other-user-paper-custumer {
  background-color: #e8ebf0 !important;
  border-radius: 30px 30px 30px 0 !important;
  font-size: 14px !important;
  color: #000000 !important;
  padding: 10px !important;
  margin-bottom: 10px;
}

.avatar {
  margin-right: 10px;
}

.message-text {
  padding: 5px 10px;
}



.message-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

/* New styles */

.timestamp-row {
    font-size: smaller;
    color: gray;
    margin-top: 5px;
}

.timestamp-row-same-user {
    font-size: smaller;
    color: #d5d4d4 !important;
    margin-top: 5px;
}


.animate {
    animation: fadeIn 1s ease-in-out, colorChange 2s infinite;
    transition: color 1s ease-in-out;
    /* background: #87CEFA; Light sky blue background to highlight the text */
    font-weight: bold; /* Make the text bold */
    border-radius: 5px; /* Rounded corners */
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes colorChange {
    0% { color: #add8e6; } /* Light blue */
    50% { color: #007bff; } /* Blue */
    100% { color: #add8e6; } /* Back to light blue */
}

/* Add more custom styling as needed */
