@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Source Sans 3', sans-serif;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0F1F3;
}


.custom-scrollbar {
     scrollbar-width: thin;
    scrollbar-color: #706a6a #f0f0f0; /* Define scrollbar colors */
}

.custom-scrollbar::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Set the color of the thumb */
    border-radius: 4px; /* Add some border radius to the thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #aaa; /* Set the color of the thumb on hover */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Set the color of the track */
}

.MuiPopover-paper {
     scrollbar-width: thin;
    scrollbar-color: #706a6a #f0f0f0; /* Define scrollbar colors */
}

.MuiPopover-paper::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
}

.MuiPopover-paper::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Set the color of the thumb */
    border-radius: 4px; /* Add some border radius to the thumb */
}

.MuiPopover-paper::-webkit-scrollbar-thumb:hover {
    background-color: #aaa; /* Set the color of the thumb on hover */
}

.MuiPopover-paper::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Set the color of the track */
}



.container-scroll {
    overflow-y: auto;
    max-height: 400px; /* Adjust the max-height as needed */
}