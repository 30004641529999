.grid-container {
    display: grid;
    gap: 8px;
    grid-template-columns: 0.65fr 1.35fr 1fr;
    height: 87vh; /* Adjust the height as needed */
    grid-template-rows: 1fr;
    grid-template-areas: "col1 col2 col3";
    overflow-x: hidden;
}

.grid-item {
    padding: 20px;
    background-color: white;
    overflow-y: auto;
    border-radius: 4px;
}

.col1 {
    grid-area: col1;
    border-radius: 10px;
    background-color: #f5f5f5;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    /* border: solid 1px #1976d2; */
}

.col2 {
    grid-area: col2;
    display: grid;
    gap: 20px;
    grid-template-rows: 90% 1fr;
    background-color: #f5f5f5;
    overflow: hidden;
}

.col2-row1 {
    overflow-y: scroll;
    overflow-x: hidden; 
}

.custom-scrollbar {
     scrollbar-width: thin;
    scrollbar-color: #706a6a #f0f0f0; /* Define scrollbar colors */
}

.custom-scrollbar::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Set the color of the thumb */
    border-radius: 4px; /* Add some border radius to the thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #aaa; /* Set the color of the thumb on hover */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Set the color of the track */
}


.col3 {
    grid-area: col3;
}


.chat-selector {
    margin:.001em!important ;
    padding: 2px;
    height: 100px;
    width: 100%;
    max-width: 22rem;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
}

.chat-user-list {
    height: 'auto';
    min-height: '1000px';
    /* background-color: white; */
    border-radius: 10px;

}

.user-details {
    margin-left: 10px;
}

.custom-popover {
  max-width: 300px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.popover-header {
  color: #ffffff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.popover-divider {
  width: 100%;
  height: 1px;
  background-color: #dee2e6;
  margin: 10px 0;
}

.popover-body {
  padding: 10px;
}

