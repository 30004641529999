

.files-list-wrapper {
  margin-top: -10px;
  margin-right: 2em;
  margin-left: 2em;
}

/* Styling for each file item */
.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5625em 1em;
  border-radius: 10px;
  background-color: #369763;
  margin-bottom: 0.7em;
  color: #fff;
}

/* Styling for the file name */
.file-name {
  flex: 1;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Styling for icon buttons */
.icon-button {
  font-size: 24px;
  margin-right: 0.5em;
  cursor: pointer;
}

/* Styling for the file size chip */
.file-size-chip {
  font-size: 24px;
  cursor: pointer;
  margin-right: 5px;
}

.icon-hover:hover {
  color: #369763; /* Change to the desired color on hover */
  background-color: #fff;
  border-radius: 6px;
}

.tooltip-content {
  text-align: left; /* Align content to the left */
  padding: 8px; /* Add padding for spacing */
}

.tooltip-content strong {
  font-weight: bold; /* Make labels bold */
  margin-right: 4px; /* Add space after labels */
}