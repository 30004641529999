
.ticket-head-div {
  color: #44546f;
  background-color: #fff; 
  outline: 1.4px solid transparent; 
  outline-offset: -1.4px; 
  font-weight: bold;
  font-size: 22px; 
  padding: 8px;
  min-height: 35px; 
  transition: background-color 0.3s; 
  border: 1.4px solid #c4c4c4; 
}

.ticket-head-no-input-error {
    border: 1.4px solid #d13e3e;
}

.ticket-head-div:focus {
  background-color: #f0f0f0;
  outline: 1.4px solid #1976d2; 
}

.ticket-head-div:hover {
  background-color: #f0f0f0; 
}



.ticket-description-div {
  color: #44546f;
  background-color: #fff; 
  outline: 1.4px solid transparent; 
  outline-offset: -1.4px; 
  font-weight: 600;
  font-size: 17px; 
  padding: 8px;
  min-height: 30px; 
  transition: background-color 0.3s; 
  border: 1.4px solid #c4c4c4; 
}

.ticket-description-div:focus {
  outline: 1.4px solid #1976d2; 
}

.ticket-description-div:hover {
  background-color: #f0f0f0; 
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f4f4f4;
  padding: 16px; /* Adjust padding as needed */
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow to the footer */
  z-index: 99;
}

.fixed-header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f4f4f4;
  padding: 16px; /* Adjust padding as needed */
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow to the footer */
  z-index: 99;
}